.not-found {
	&__content {
		min-height: 50vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	&__title {
		font-family: 'Exo 2';
		font-size: 50px;
		color: #fff;
		text-align: center;
	}
}
