.documents {
	margin: 120px 0 80px 0;
	color: #fff;

	&__title {
		font-size: 36px;
		text-align: center;
		margin-bottom: 20px;
	}

	&__description {
		text-align: center;
		font-size: 16px;
		margin: 0 auto 10px auto;
		max-width: 795px;
	}

	&__email {
		color: #f48020;
		text-decoration: none;
	}

	&__buttons {
		margin: 20px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		&__button {
			text-align: center;
			background-color: #f48020;
			border: none;
			padding: 10px 20px;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
			margin: 5px;
			border-radius: 5px;
			transition: background-color 0.3s;

			&:hover {
				background-color: #e56a00;
			}

			&--primary {
				background-color: #f48020;
			}
		}
	}

	&__tabs {
		display: flex;
		max-width: 880px;

		margin: 20px auto 20px auto;
		overflow-x: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}

		&__tab {
			position: relative;
			color: #fff;
			padding: 10px 20px;
			cursor: pointer;
			margin: 0 5px;
			transition: 0.3s ease;
			flex-shrink: 0;
			white-space: nowrap;

			&:hover {
				color: #f48020;
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 0;
				height: 2px;
				background-color: #f48020;
				transition: all 0.3s ease;
				transform: translateX(-50%);
			}

			&:hover::after {
				width: 100%;
			}

			&--active {
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 100%;
					height: 2px;
					background-color: #f48020;
				}
			}
		}
	}

	&__content {
		font-size: 16px;
		margin: 20px 0 80px 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 15px;
	}

	&__rules-link {
		font-size: 14px;
		color: #f48020;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	/* Адаптация для мобильных устройств */
	@media (max-width: 768px) {
		margin-top: 60px;
		padding: 10px;

		&__title {
			font-size: 24px;
		}

		&__description {
			font-size: 14px;
		}

		&__buttons {
			flex-direction: column;
			align-items: center;

			&__button {
				width: 100%;
				max-width: 300px;
				margin: 5px 0;
				padding: 12px 20px;
				font-size: 14px;
			}
		}

		&__tabs {
			justify-content: flex-start;
			padding: 0 10px;
			margin: 20px -10px;

			&__tab {
				padding: 8px 16px;
				font-size: 14px;
			}
		}

		&__content {
			font-size: 14px;
		}

		&__rules-link {
			font-size: 12px;
		}
	}
}
