.risk {
	margin: 100px 0;
	color: #fff;
	&__info {
		margin-bottom: 40px;
	}
	&__title {
		font-size: 20px;
		font-weight: bold;
		padding-left: 10px;
		border-left: 5px solid #f48020;
		margin-bottom: 25px;
	}
	&__text {
		font-size: 18px;
		line-height: 140%;
		&-gray {
			display: inline-block;
			margin-top: 20px;
			font-size: 16px;
			color: #757575;
		}
		&-small {
			font-size: 14px;
			margin-bottom: 20px;
		}
		@media (max-width: 768px) {
			font-size: 14px;
		}
	}
	&__list {
		margin: 30px 0 15px 0;
		display: flex;
		flex-direction: column;
		gap: 20px;
		&-item {
			font-size: 18px;
			display: flex;
			align-items: start;
			gap: 25px;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			svg {
				min-width: 25px;
			}
		}
	}
	&__important {
		margin-bottom: 40px;
		display: flex;
		align-items: start;
		gap: 10px;
		svg {
			min-width: 30px;
		}
		p {
			font-size: 13px;
			max-width: 800px;
		}
	}
}

.margin_unset {
	margin: 0;
}
.fz_16 {
	font-size: 16px;
}
.fz_14 {
	font-size: 14px;
}
