@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Exo+2:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
html,
body {
	height: 100%;
	margin: 0;
}
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

::-webkit-scrollbar {
	width: 7px; /* Ширина вертикального скроллбара */
}

::-webkit-scrollbar-thumb {
	background-color: #f48020; /* Цвет бегунка */
	border-radius: 5px; /* Радиус закругления углов */
}

::-webkit-scrollbar-track {
	background: #141a23; /* Цвет дорожки */
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
}

ul,
ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img,
svg {
	max-width: 100%;
	height: auto;
}

address {
	font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button,
input[type='submit'] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
	outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}
body {
	font-family: 'Roboto', sans-serif;
	background-color: #141a1f;
}
.wrap {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
}
.content {
	flex: 1;
}
.container {
	max-width: 1480px;
	padding: 0 20px;
	margin: 0 auto;
}
.title {
	text-align: center;
	color: #fff;
	font-size: 28px;
	margin-bottom: 20px;

	@media (min-width: 768px) {
		font-size: 36px;
	}
}
