.onboard {
	max-width: 860px;
	margin: 0 auto 80px auto;
	&__title {
		text-align: center;
		color: #fff;
		font-size: 24px;
		margin-bottom: 20px;
	}
	&__list {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	&__item {
		a {
			font-size: 15px;
			color: #f48020;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
