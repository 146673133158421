.about_documents {
	margin-bottom: 40px;

	&__title {
		margin-bottom: 20px;
		color: #fff;
	}

	&__container {
		display: flex;
		border: 2px solid #ffa65c;
		border-radius: 5px;
		overflow: hidden;
		min-height: 490px;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	&__tabs-container {
		min-width: 340px;
		border-right: 1px solid rgba(255, 255, 255, 0.1);

		@media (max-width: 768px) {
			width: 100%;
			border-right: none;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		}
	}

	&__tabs {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 10px;
		overflow-y: auto;
		height: 100%;
		scroll-snap-type: y mandatory;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		@media (max-width: 768px) {
			justify-content: start;
			flex-direction: row;
			overflow-x: auto;
			overflow-y: hidden;
			scroll-snap-type: x mandatory;
		}
	}

	&__tab {
		background: none;
		border: none;
		color: #fff;
		font-size: 16px;
		text-align: left;
		padding: 15px 10px;
		cursor: pointer;
		transition: background-color 0.3s;
		white-space: nowrap;
		scroll-snap-align: start;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}

		@media (max-width: 768px) {
			&:hover {
				background: transparent;
			}
		}
		&--active {
			border-left: 2px solid #f48020;

			@media (max-width: 768px) {
				border-left: none;
				border-bottom: 2px solid #f48020;
			}
		}
	}

	&__content-wrapper {
		flex-grow: 1;
	}

	&__content {
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		&-image-wrapper {
			flex: 1;
			margin-right: 20px;
			max-width: 300px;

			@media (max-width: 768px) {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}

		&-image {
			max-width: 100%;
			height: auto;
		}

		&-info {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&-title {
			font-size: 20px;
			margin-bottom: 20px;
			color: #fff;
			max-width: 500px;
		}

		&-button {
			background-color: #f48020;
			color: #fff;
			border: none;
			padding: 10px 20px;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.3s;
			align-self: flex-start;

			&:hover {
				background-color: darken(#f48020, 10%);
			}
		}
	}
}
