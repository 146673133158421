.footer {
	background-color: #141a23;
	color: #ffffff;
	padding: 40px 0;

	&__container {
		display: flex;
		justify-content: space-between;
	}

	&__left {
		flex: 0 0 40%;
		margin-right: 40px;
	}

	&__right {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__title {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 15px;
	}

	&__description {
		font-size: 14px;
		margin-bottom: 20px;
		color: #a0a0a0;
	}

	&__social {
		display: flex;
		gap: 15px;
	}

	&__social-link {
		display: block;
	}

	&__social-icon {
		width: 24px;
		height: 24px;
	}

	&__column {
		flex: 0 0 calc(30% - 20px);
		margin-bottom: 20px;
	}

	&__subtitle {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 15px;
		color: #ffffff;
	}

	&__list {
		list-style: none;
		padding: 0;
	}

	&__item {
		margin-bottom: 10px;
	}

	&__link {
		color: #a0a0a0;
		text-decoration: none;
		font-size: 14px;
		transition: color 0.3s ease;

		&:hover {
			color: #ffffff;
		}
	}

	&__bottom {
		border-top: 1px solid #3a3a3a;
		margin-top: 20px;
		padding-top: 20px;
		text-align: center;
	}

	&__copyright {
		font-size: 12px;
		color: #a0a0a0;
	}
}

@media (max-width: 868px) {
	.footer {
		&__container {
			flex-direction: column;
		}

		&__left {
			margin-right: 0;
			margin-bottom: 40px;
		}

		&__right {
			flex-direction: column;
		}

		&__column {
			flex-basis: 100%;
		}
	}
}
