.contacts {
	margin: 100px 0;
	&__title {
		font-family: 'Exo 2';
		text-align: center;
		color: #fff;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 20px;
		@media (min-width: 768px) {
			font-size: 44px;
		}
	}
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 50px;
		max-width: 1000px;
		margin: 0 auto;
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
	&__map {
		width: 100%;
	}
	&__info {
		padding: 30px;
		border: 1px solid #f48020;
		background: #1d232c;
		border-radius: 5px;
		&-list {
			display: flex;
			align-items: start;
			flex-direction: column;
			gap: 15px;
		}
		&-item {
			font-size: 18px;
			color: #fff;
		}
	}
}
