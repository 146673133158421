.partners {
	margin-bottom: 100px;

	&__img {
		width: 100%;
		filter: grayscale(100%) brightness(0) invert(1);
	}

	&__slide {
		max-width: 295px;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 3px;

		@media (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__col {
		width: 100%;
	}

	&__link {
		width: 100%;
		min-height: 220px;
		padding: 0 15px;
		background: #f48020;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 500px) {
			min-height: 200px;
		}
	}

	&__img-block {
		&--partner1 {
			max-width: 160px;
			width: 100%;
		}

		&--partner2 {
			max-width: 200px;
			width: 100%;
		}

		&--partner3 {
			max-width: 150px;
			width: 100%;
		}

		&--partner4 {
			max-width: 170px;
			width: 100%;
		}

		&--partner5 {
			max-width: 150px;
			width: 100%;
		}

		&--partner6 {
			max-width: 140px;
			width: 100%;
		}

		&--partner7 {
			max-width: 162px;
			width: 100%;
		}

		&--partner8 {
			max-width: 160px;
			width: 100%;
		}
	}
}
