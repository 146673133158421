.header {
	padding: 15px 0;
	position: fixed;
	width: 100%;
	z-index: 100;
	transition: background-color 0.3s ease;
	background-color: rgba(20, 26, 35, 0.5);
	backdrop-filter: blur(10px);

	&--home {
		background-color: transparent;
		backdrop-filter: none;
		-webkit-backdrop-filter: none;
	}

	&--scrolled {
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		background-color: rgba(20, 26, 35, 0.5);
	}

	&__content {
		display: flex;
		gap: 50px;
		align-items: center;
	}

	&__mobile-toggle {
		display: none;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		width: 100%;
		&-list {
			display: flex;
			list-style-type: none;
			margin: 0;
			padding: 0;
			gap: 30px;
		}

		&-item {
			position: relative;
		}

		&-link {
			color: #ffffff;
			text-decoration: none;
			font-size: 1rem;
			padding: 0.5rem 0;
			transition: color 0.3s ease;
			background: none;
			border: none;
			cursor: pointer;
			display: flex;
			align-items: center;

			&:hover {
				color: #f48020;
			}
		}
	}

	&__dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		background-color: #2a3441;
		padding: 1rem;
		border-radius: 5px;
		z-index: 10;
		min-width: 200px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
		transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

		&--open {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	&__dropdown-item {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__dropdown-link {
		color: #ffffff;
		text-decoration: none;
		font-size: 14px;
		transition: color 0.3s ease;

		&:hover {
			color: #f48020;
		}
	}

	@media (max-width: 600px) {
		&__content {
			justify-content: space-between;
		}

		&__mobile-toggle {
			display: block;
			background: none;
			border: none;
			cursor: pointer;
			width: 30px;
			height: 20px;
			position: relative;
			z-index: 1000;

			&-icon {
				&,
				&::before,
				&::after {
					content: '';
					position: absolute;
					height: 2px;
					width: 100%;
					right: 0;
					background-color: #ffffff;
					transition: all 0.3s ease;
				}
				&::before {
					top: -8px;
				}

				&::after {
					bottom: -8px;
				}
			}

			&--open {
				.header__mobile-toggle-icon {
					background-color: transparent;

					&::before {
						top: 0;
						transform: rotate(45deg);
					}

					&::after {
						bottom: 0;
						transform: rotate(-45deg);
					}
				}
			}
		}

		&__language-switcher {
			.header__lang-button {
				font-size: 1rem;
				padding: 8px 12px;
			}
		}

		&__nav {
			gap: 20px;
			position: fixed;
			width: 100%;
			height: 100vh;
			top: 0;
			right: 0;
			background-color: #1e2530;
			transform: translateX(100%);
			transition: transform 0.3s ease;
			z-index: 999;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&--open {
				transform: translateX(0);
			}

			&-list {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			&-link {
				display: block;
				font-size: 1.2rem;
			}
		}

		&__dropdown-menu {
			position: static;
			background-color: transparent;
			box-shadow: none;
			padding: 0.5rem 0 0.5rem 1rem;
			transform: translateY(0);
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;

			&--open {
				max-height: 1000px;
			}
		}
	}
}

.icon {
	transition: 0.3s ease;

	&--rotated {
		transform: rotate(180deg);
	}
}
.language-switcher {
	background: #222222;
	border-radius: 25px;
	padding: 4px;
	display: inline-flex;
	position: relative;

	&__button {
		font-size: 14px;
		border: none;
		padding: 8px 20px;
		cursor: pointer;
		background: none;
		position: relative;
		z-index: 1;
		color: #ffffff;
		transition: 0.3s ease;

		&--active {
			color: #fff;
		}
	}

	&__slider {
		position: absolute;
		top: 4px;
		left: 4px;
		background: #f48020;
		border-radius: 20px;
		height: calc(100% - 8px);
		width: calc(50% - 4px);
		transition: 0.3s ease;
	}

	&[data-active='en'] {
		.language-switcher__slider {
			transform: translateX(100%);
		}
	}
}
