$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

.news-block {
	color: #fff;
	margin-bottom: 120px;

	&__columns {
		display: flex;
		justify-content: space-between;

		@media (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}
	}

	&__column {
		width: 48%;

		@media (max-width: $breakpoint-tablet) {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	&__subtitle {
		color: #f48020;
		font-size: 18px;
		margin-bottom: 15px;
	}

	&__list {
		list-style-type: none;
		padding: 0;
	}

	&__item {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #30363d;
		a {
			color: #fff;
			transition: 0.3s ease;
			&:hover {
				color: #57a6ff;
				text-decoration: underline;
			}
		}
		@media (max-width: $breakpoint-mobile) {
			font-size: 14px;
		}
	}

	&__date {
		color: #8b949e;
		display: block;
		margin-bottom: 5px;
	}

	&__link {
		color: #57a6ff;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
