.listing {
	margin: 120px 0;
	min-height: 445px;
	&__content {
		color: #ffffff;
	}

	&__info {
		display: flex;
		flex-direction: column;
	}

	&__tabs {
		display: flex;
		overflow-x: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		-ms-overflow-style: none;
		margin-bottom: 30px;

		&::-webkit-scrollbar {
			display: none;
		}

		@media (min-width: 768px) {
			justify-content: space-between;
			margin-bottom: 50px;
		}
	}

	&__tab {
		background: none;
		border: none;
		color: #ffffff;
		cursor: pointer;
		font-size: 14px;
		padding: 10px 15px;
		transition: color 0.3s ease;
		flex-shrink: 0;

		&:hover {
			color: #f48020;
		}

		&--active {
			color: #f48020;
			border-bottom: 2px solid #f48020;
		}

		@media (min-width: 768px) {
			font-size: 16px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__companies-container {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (min-width: 768px) {
			flex-direction: row;
			gap: 40px;
		}
	}

	&__categories {
		display: flex;
		flex-direction: row;
		overflow-x: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}

		@media (min-width: 768px) {
			flex-direction: column;
			align-items: start;
			overflow-x: visible;
		}
	}

	&__category-button {
		background: none;
		border: none;
		color: #fff;
		cursor: pointer;
		font-size: 14px;
		padding: 10px 15px;
		transition: color 0.3s ease;
		flex-shrink: 0;

		&:hover {
			color: #f48020;
		}

		&--active {
			color: #f48020;
			border-bottom: 1px solid #f48020;

			@media (min-width: 768px) {
				border-bottom: none;
				border-left: 1px solid #f48020;
			}
		}

		@media (min-width: 768px) {
			font-size: 16px;
			padding: 10px 0 10px 5px;
		}
	}

	&__company-list {
		display: flex;
		flex-direction: column;
	}

	&__company-item {
		color: #ffffff;
		font-size: 14px;
		margin-bottom: 10px;

		@media (min-width: 768px) {
			font-size: 16px;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
	}

	&__item {
		color: #fff;
		cursor: pointer;
		font-size: 14px;
		margin-bottom: 10px;
		transition: color 0.3s ease;

		&:hover {
			color: #f48020;
		}

		&--link {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		@media (min-width: 768px) {
			font-size: 16px;
		}
	}
}
