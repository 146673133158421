.news {
	margin: 120px 0;
	&__content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		@media (max-width: 1200px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 425px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&__col_hide {
		display: none;
	}

	&__card {
		cursor: pointer;
		&-title {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: calc(22 / 16 * 100%);
			color: #fff;
			//max-width: 250px;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		&-image--block {
			width: 100%;
			height: 350px;
			margin-bottom: 10px;
			border-radius: 4px;
			overflow: hidden;
		}

		&-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}

		&-date {
			font-weight: 400;
			font-size: 14px;
			color: #f48020;
			margin-bottom: 3px;
		}
	}
}
