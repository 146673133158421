.members {
	max-width: 860px;
	margin: 0 auto;
	color: #ffffff;

	&__tabs {
		display: flex;
		max-width: 600px;
		margin: 0 auto 20px auto;
		overflow-x: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none; // Firefox
		-ms-overflow-style: none; // IE 10+

		&::-webkit-scrollbar {
			display: none; // Chrome, Safari, newer versions of Opera
		}
	}

	&__tab {
		background: none;
		border: none;
		color: #ffffff;
		padding: 10px 20px;
		cursor: pointer;
		font-size: 16px;
		transition: color 0.3s;
		flex-shrink: 0;

		&:hover {
			color: #f48020;
		}

		&--active {
			color: #f48020;
			border-bottom: 2px solid #f48020;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
		border-bottom: 1px solid #3a3d4a;
		font-weight: bold;
	}

	&__header-item {
		flex: 1;
		&:last-child {
			text-align: right;
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
		border-bottom: 1px solid #3a3d4a;
	}

	&__name {
		flex: 1;
	}

	&__website {
		color: #f48020;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.legislation {
	&__tabs {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		overflow-x: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__tab {
		background: none;
		border: none;
		color: #ffffff;
		padding: 10px 20px;
		cursor: pointer;
		font-size: 16px;
		transition: color 0.3s;
		flex-shrink: 0;

		&:hover {
			color: #f48020;
		}

		&--active {
			color: #f48020;
			border-bottom: 2px solid #f48020;
		}
	}

	&__list {
		list-style-type: none;
		padding: 0;
	}

	&__item {
		padding: 10px 0;
		border-bottom: 1px solid #3a3d4a;
		cursor: pointer;

		a {
			font-size: 14px;
			color: #f48020;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

@media (max-width: 768px) {
	.members {
		&__tabs {
			flex-wrap: nowrap;
			justify-content: flex-start;
		}

		&__tab {
			width: auto;
			text-align: center;
			padding: 15px 20px;
			border-bottom: none;

			&--active {
				border-bottom: 2px solid #f48020;
			}
		}
		&__name {
			margin-bottom: 5px;
		}

		&__website {
			align-self: flex-start;
		}
	}

	.legislation {
		&__item {
			padding: 15px 0;
		}
	}
}
